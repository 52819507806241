<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="float-start mb-0">{{ memoirProfileId ? 'Edit' : 'New' }} memoir profile <strong>{{ item.memoir ? item.memoir.year : '' }}</strong><br>
                <small>{{ item.user ? item.user.full_name : '' }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="(item.submited_date || (!item.memoir.open && !item.open)) && loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back "
              @click="viewOriginal"
            >View {{ !viewFreeze ? 'original' : 'admin' }}</a>
            <a
              class="btn btn-icon btn-secondary ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back "
              @click="$router.push({name: loggedUser.roles.includes('super-admin') ? 'researcher.memoir-profile.index' : 'researcher.memoir.index' })"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div
            v-if="!loading"
            class="col-12"
          >
            <!-- ACCESS -->
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="card"
            >
              <div
                v-b-toggle="`collapseAccess`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Access <span
                    class="text-warning"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Special access active / Memoir proof access active"
                  ><i data-feather="alert-triangle" /></span></a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <b-collapse
                  :id="`collapseAccess`"
                  visible
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Open access</label>
                          <div class="form-check mb-1">
                            <input
                              id="openaccess"
                              class="form-check-input"
                              type="checkbox"
                              value="checked"
                              checked
                              disabled
                            >
                            <label
                              class="form-check-label"
                              for="openaccess"
                            >Yes</label>
                          </div>
                          <input
                            id="openaccess-date"
                            type="text"
                            class="form-control flatpickr-basic disabled"
                            value="2023/12/25"
                            disabled
                          >
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Special access</label>
                          <div class="form-check mb-1">
                            <input
                              id="specialaccess"
                              v-model="item.special_access"
                              class="form-check-input"
                              type="checkbox"
                              @change="!$event.target.checked ? item.special_access_deadline = null : ''"
                            >
                            <label
                              class="form-check-label"
                              for="specialaccess"
                            >Yes</label>
                          </div>
                          <date-picker
                            v-model="item.special_access_deadline"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                            :disabled="!item.special_access"
                          />
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Memoir proof access</label>
                          <div class="form-check mb-1">
                            <input
                              id="proofaccess"
                              v-model="item.proof_access"
                              class="form-check-input"
                              type="checkbox"
                              value="unchecked"
                              @change="!$event.target.checked ? item.proof_access_deadline = null : ''"
                            >
                            <label
                              class="form-check-label"
                              for="proofaccess"
                            >Yes</label>
                          </div>
                          <date-picker
                            v-model="item.proof_access_deadline"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                            :disabled="!item.proof_access"
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </b-collapse>
              </div>
            </div>

            <!-- <h3>Information to <strong>Memoir {{ item.memoir ? item.memoir.year : '' }}</strong></h3> -->
            <p><a
              href="https://portal.icrea.cat/intranet/knowledge-base/show/6"
              target="_blank"
              class="text-primary link-underline"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Go to the instructions in Knowledge Base"
            >Show instructions</a></p>

            <!-- PERSONAL INFORMATION -->
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Personal information</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div
                          v-if="blob_url || icon_path"
                          class="col-sm-4"
                        >
                          <img
                            v-if="blob_url"
                            class="img-fluid"
                            :alt="item.alt_text"
                            :src="blob_url"
                          >
                          <img
                            v-if="icon_path"
                            class="img-fluid"
                            :alt="item.alt_text"
                            :src="icon_path"
                          >
                        </div>
                        <div
                          v-if="loggedUser.roles.includes('super-admin')"
                          :class="loggedUser.roles.includes('super-admin') ? 'col-sm-3' : 'col-3'"
                        >
                          <div
                            v-if="loggedUser.roles.includes('super-admin')"
                            class="mb-1"
                          >
                            <div
                              class="input-group custom-file-button"
                            >
                              <label
                                class="input-group-text"
                                for="formFileImage"
                              >Select image</label>
                              <input
                                id="formFileImage"
                                :ref="`file-profile-image`"
                                class="form-control"
                                type="file"
                                @change="uploadFile('file-profile-image')"
                              >
                            </div>
                          </div>
                          <small
                            v-if="loggedUser.roles.includes('super-admin')"
                            class="mt-1"
                          >
                            Files must be less than <strong>2 MB</strong>.<br>
                            Allowed file types: <strong>png, gif, webp or jpg</strong>.
                          </small>
                          <p
                            v-if="loggedUser.roles.includes('super-admin')"
                            class="mt-1"
                          >
                            <a
                              href=""
                              class="btn bg-light-danger btn-sm"
                            ><i data-feather="trash-2" /> Delete photo</a>
                          </p>
                          <div
                            v-if="loggedUser.roles.includes('super-admin')"
                            class="mb-1"
                          >
                            <label
                              for=""
                              class="form-label"
                            >Alternate text</label>
                            <input
                              v-model="item.alt_text"
                              type="text"
                              class="form-control"
                              :disabled="!canEdit"
                            >
                          </div>
                          <small v-if="loggedUser.roles.includes('super-admin')">This text will be used by screen readers, search engines, or when the image cannot be loaded</small>
                        </div>
                        <div
                          :class="loggedUser.roles.includes('super-admin') ? 'col-5' : 'col-8'"
                        >
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="mb-1">
                                <label
                                  for=""
                                  class="form-label"
                                >Name to appear in the Memoir (Change if required)</label>
                                <input
                                  v-model="item.name_on_memoir"
                                  type="text"
                                  class="form-control"
                                  :disabled="!canEdit"
                                >
                              </div>
                            </div>
                            <div
                              v-if="loggedUser.roles.includes('super-admin')"
                              class="col-sm-12"
                            >
                              <div class="mb-1">
                                <label
                                  for=""
                                  class="form-label"
                                >Status</label>
                                <v-select
                                  v-model="item.status"
                                  label="status"
                                  :options="statuses"
                                  :get-option-key="option => option.id"
                                  :disabled="!canEdit"
                                />
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class="mb-1">
                                <label
                                  for=""
                                  class="form-label"
                                >Area</label>
                                <v-select
                                  v-if="loggedUser.roles.includes('super-admin') && canEdit"
                                  v-model="item.area"
                                  label="name"
                                  :options="areas"
                                  :get-option-key="option => option.id"
                                />
                                <p v-else>
                                  {{ item.area ? item.area.name : '---' }}
                                </p>
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class="mb-1">
                                <label
                                  for=""
                                  class="form-label"
                                >Institution Host</label>
                                <v-select
                                  v-if="loggedUser.roles.includes('super-admin') && canEdit"
                                  v-model="item.host_1"
                                  label="name"
                                  :options="institutions"
                                  :get-option-key="option => option.id"
                                  @search="onSearch({ name: $event }, 'institutions/filter')"
                                />
                                <p v-else>
                                  {{ item.host_1 ? `${item.host_1.name} - (${item.host_1.code})` : (item.host_institution1 ? `${item.host_institution1.name} - (${item.host_institution1.code})` : '---') }}
                                </p>
                              </div>
                            </div>
                            <div
                              v-if="item.host_2"
                              class="col-sm-12"
                            >
                              <div class="mb-1">
                                <label
                                  for=""
                                  class="form-label"
                                >Institution Host 2</label>
                                <v-select
                                  v-if="loggedUser.roles.includes('super-admin') && canEdit"
                                  v-model="item.host_2"
                                  label="name"
                                  :options="institutions"
                                  :get-option-key="option => option.id"
                                  @search="onSearch({ name: $event }, 'institutions/filter')"
                                />
                                <p v-else>
                                  {{ item.host_2 ? item.host_2.name : '---' }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <!-- SHORT BIOGRAPHY -->
                <div class="card">
                  <div class="card-header">
                    <h3 class="cart-title">
                      <a data-action="collapse">Short biography</a>
                    </h3>
                    <div class="heading-elements">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"><i data-feather="chevron-down" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-content collapse show">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-sm-10">
                              <p style="font-size:10px">
                                Dynamic section - This is the text that you currently have in the "Short biography" section of your ICREA's webpage. If you modify this text here and click on ‘save & submit’, the text the section "Short biography" of My profile gets changed (this is the text that shows on the website). Now, if you make changes to this section from My profile, to bring those changes to the Memoir you must click on the "copy from my profile" button.
                              </p>
                            </div>
                            <div
                              v-if="canEdit"
                              class="col-sm-2"
                            >
                              <a
                                class="btn btn-dark btn-sm w-100"
                                @click="item.short_biography = item.user.short_biography"
                              >Copy from my profile</a>
                            </div>
                          </div>
                          <div class="mb-1 required">
                            <label
                              for=""
                              class="form-label disp-bl"
                            >Short biography text <span
                              v-if="canEdit"
                              class="text-danger float-end"
                            >{{ contentLength }}/1000</span></label><!-- editor -->
                            <div id="full-wrapper">
                              <div id="full-container">
                                <quill-editor
                                  v-if="canEdit"
                                  ref="quillTest"
                                  v-model="item.short_biography"
                                />
                                <div
                                  v-else
                                  class="editor"
                                  style="height:300px"
                                  v-html="item.short_biography"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <!-- RESEARCH INTEREST -->
                <div class="card">
                  <div class="card-header">
                    <h3 class="cart-title">
                      <a data-action="collapse">Research interest</a>
                    </h3>
                    <div class="heading-elements">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"><i data-feather="chevron-down" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-content collapse show">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-sm-10">
                              <p style="font-size:10px">
                                Dynamic section - This is the text that you currently have in the "Research interests" section of your ICREA's webpage. If you modify this text here and click on ‘save & submit’, the text the section "Research interests" of My profile gets changed (this is the text that shows on the website). Now, if you make changes to this section from My profile, to bring those changes to the Memoir you must click on the "copy from my profile" button.
                              </p>
                            </div>
                            <div class="col-sm-2">
                              <a
                                v-if="canEdit"
                                class="btn btn-dark btn-sm w-100"
                                @click="item.research_interest = item.user.research_interest"
                              >Copy from my profile</a>
                            </div>
                          </div>

                          <div class="mb-1 required">
                            <label
                              for=""
                              class="form-label disp-bl"
                            >Research interests text <span
                              v-if="canEdit"
                              class="text-danger float-end"
                            >{{ contentResearchLength }}/1000</span></label><!-- editor -->
                            <div id="full-wrapper">
                              <div id="full-container">
                                <quill-editor
                                  v-if="canEdit"
                                  ref="quillResearch"
                                  v-model="item.research_interest"
                                />
                                <div
                                  v-else
                                  class="editor"
                                  style="height:300px"
                                  v-html="item.research_interest"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <!-- PUBLICATIONS -->
                <MemoirProfilePublications
                  v-if="!loading"
                  :memoir-profile-id="item.id"
                  :memoir-profile-year="item.memoir && item.memoir.year"
                  :memoir-profile-user="item.user"
                  :can-edit="canEdit"
                  :content-publication-length="contentPublicationLength"
                  @publicationReferenceLength="publicationReferenceLength"
                />
              </div>
              <div class="col-6">
                <!-- OTHER ACTIVITIES -->
                <div class="card">
                  <div class="card-header">
                    <h3 class="cart-title">
                      <a data-action="collapse">Other activities</a>
                    </h3>
                    <div class="heading-elements">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"><i data-feather="chevron-down" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-content collapse show">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <div class="mb-1 required">
                            <label
                              for=""
                              class="form-label disp-bl"
                            >Other activities text <span
                              v-if="canEdit"
                              class="text-danger float-end"
                            >{{ contentOtherActivitiesLength }}</span></label><!-- editor -->
                            <div id="full-wrapper">
                              <div id="full-container">
                                <quill-editor
                                  v-if="canEdit"
                                  ref="quillOtherActivities"
                                  v-model="item.other_activities"
                                />
                                <div
                                  v-else
                                  class="editor"
                                  v-html="item.other_activities"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3" />
              <div class="col-6">
                <!-- STATS -->
                <div class="card">
                  <div class="card-header">
                    <h3 class="cart-title">
                      <a data-action="collapse">Character counter</a>
                    </h3>
                    <div class="heading-elements">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"><i data-feather="chevron-down" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-content collapse show">
                    <div class="card-body">
                      <p>The total number of characters of publications and other research activities must be less than 2000!</p>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="card shadow-none bg-transparent border-secondary">
                            <div class="card-body">
                              <h6>Publications</h6>
                              <h4>{{ contentPublicationLength }}</h4>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="card shadow-none bg-transparent border-secondary">
                            <div class="card-body">
                              <h6>Other research activities</h6>
                              <h4>{{ contentOtherActivitiesLength }}</h4>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="card shadow-none bg-transparent border-secondary">
                            <div class="card-body">
                              <h6>Total</h6>
                              <h4 :class="totalLength <= maxLength ? 'text-success' : 'text-danger'">
                                {{ totalLength }}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3" />
              <div class="col-12">
                <Grants v-if="!loading && item.id" />
              </div>
            </div>

            <InternalNotes
              :notes="item.notes"
              @saveNewNote="saveNewNote"
            />

          </div>
          <div
            v-else
            class="loading custom-loading"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
        </div>

        <div
          v-if="canEdit"
          class="page-actions d-flex align-items-center justify-content-between"
        >
          <div>
            <button
              v-if="!sendingDraft"
              class="btn btn-info"
              @click="save(false, 'draft')"
            >
              <i data-feather="save" /> Save draft
            </button>
            <button
              v-if="sendingDraft"
              class="btn btn-outline-info"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Saving draft...</span>
            </button>
            <button
              v-if="!sending"
              id="type-success"
              class="btn btn-success mx-1"
              @click="save(true, 'submit')"
            >
              <i data-feather="save" /> Save and submit
            </button>
            <button
              v-else
              class="btn btn-outline-success"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Saving...</span>
            </button>
          </div>
          <!-- <div>
            <button class="btn btn-outline-danger">
              <i data-feather="save" /> Cancel
            </button>
            <button class="btn btn-danger">
              <i data-feather="save" /> Delete
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import MemoirProfilePublications from '../components/MemoirProfilePublications.vue'
import 'vue2-datepicker/index.css'
import Grants from '../components/Grants.vue'

export default {
  components: {
    vSelect,
    DatePicker,
    BCollapse,
    MemoirProfilePublications,
    InternalNotes,
    Grants,
  },
  data() {
    return {
      memoirProfileId: this.$route.params.id,
      sending: false,
      sendingDraft: false,
      errors: '',
      icon_path: '',
      blob_url: '',
      contentLength: 0,
      contentResearchLength: 0,
      contentOtherActivitiesLength: 0,
      contentPublicationLength: 0,
      totalLength: 0,
      maxLength: 2000,
      canEdit: true,
      loading: true,
      viewFreeze: false,
    }
  },
  computed: {
    ...mapGetters({
      item: 'memoirsProfile/item',
      routeName: 'memoirsProfile/fromRouteName',
      statuses: 'status/statuses',
      loggedUser: 'auth/admin',
      areas: 'areas/areas',
      institutions: 'institutions/institutions',
    }),
    checkCharacterCounter() {
      return this.item.short_biography
    },
    checkInterestCharacterCounter() {
      return this.item.research_interest
    },
    checkOtherActivitiesCharacterCounter() {
      return this.item.other_activities
    },
  },
  watch: {
    checkCharacterCounter() {
      if (this.$refs.quillTest.quill.getLength() - 1 > 1000) {
        this.$refs.quillTest.quill.deleteText(1000, this.$refs.quillTest.quill.getLength() - 1)
      }
      this.contentLength = this.$refs.quillTest.quill.getLength() - 1
    },
    checkInterestCharacterCounter() {
      if (this.$refs.quillResearch.quill.getLength() - 1 > 1000) {
        this.$refs.quillResearch.quill.deleteText(1000, this.$refs.quillResearch.quill.getLength() - 1)
      }
      this.contentResearchLength = this.$refs.quillResearch.quill.getLength() - 1
    },
    checkOtherActivitiesCharacterCounter() {
      this.contentOtherActivitiesLength = this.$refs.quillOtherActivities.quill.getLength() - 1
      this.recalculateTotalLength()
    },
  },
  async mounted() {
    if (this.memoirProfileId) {
      this.loading = true
      await this.$store.dispatch('memoirsProfile/fetchId', this.memoirProfileId)
      this.icon_path = this.item.image_url
      this.loading = false
      if (this.loggedUser.roles.includes('super-admin')) {
        this.$store.dispatch('status/filterStatus', 'Memoirs profiles')
        this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
      }
      this.canEdit = (!this.item.submited_date && (this.item.memoir.open || this.item.open)) || this.loggedUser.roles.includes('super-admin')
      if (this.canEdit) {
        this.contentLength = this.$refs.quillTest.quill.getLength() - 1
        this.contentResearchLength = this.$refs.quillResearch.quill.getLength() - 1
      }
      this.contentOtherActivitiesLength = this.$refs.quillOtherActivities.quill.getLength() - 1
      if (!this.total_length) {
        this.recalculateTotalLength()
      }
    } else {
      await this.$store.dispatch('memoirsProfile/cleanType')
    }

    if (this.loggedUser.roles.includes('super-admin')) {
      this.$store.dispatch('status/filterStatus', 'Memoirs profiles')
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
  },
  methods: {
    async uploadFile(name) {
      const file = this.$refs[name].files[0]
      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: name,
      }

      this.item.image = fileData
      this.icon_path = ''
      this.blob_url = URL.createObjectURL(file)
    },
    async save(submit, type) {
      if (!this.item.name_on_memoir) {
        Vue.swal('Name to appear in the Memoir field is required ', '', 'error')
        return
      }
      if (type === 'submit') {
        this.sending = true
      } else {
        this.sendingDraft = true
      }
      if (this.totalLength <= this.maxLength) {
        try {
          if (this.memoirProfileId) {
            this.item.submit = submit
            this.item.publication_length = this.contentPublicationLength
            this.item.other_research_activity_length = this.contentOtherActivitiesLength
            await this.$store.dispatch('memoirsProfile/update', { id: this.memoirProfileId, data: this.item })
          } else {
            await this.$store.dispatch('memoirsProfile/create', this.item)
          }
          this.errors = ''
        } catch (e) {
          this.errors = e.response.data.message
        }

        if (this.errors === '' && this.item.id) {
          Vue.swal('The memoir profile has been saved correctly!', '', 'success').then(() => {
            this.$router.push({ name: 'researcher.memoir-profile.index' })
          })
        } else {
          // Vue.swal
        }
      } else {
        Vue.swal(`Total must to be less than ${this.maxLength} characters`, '', 'warning')
      }

      if (type === 'submit') {
        this.sending = false
      } else {
        this.sendingDraft = false
      }
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    recalculateTotalLength() {
      this.totalLength = this.contentOtherActivitiesLength + this.contentPublicationLength
    },
    publicationReferenceLength(publicationReferenceLength) {
      this.contentPublicationLength = publicationReferenceLength
      this.recalculateTotalLength()
    },
    async viewOriginal() {
      this.loading = true
      if (!this.viewFreeze) {
        await this.$store.dispatch('memoirsProfile/fetchOriginal', this.memoirProfileId)
      } else {
        await this.$store.dispatch('memoirsProfile/fetchId', this.memoirProfileId)
      }
      this.canEdit = this.viewFreeze
      this.viewFreeze = !this.viewFreeze
      this.loading = false
    },
  },
}
</script>
